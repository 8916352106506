import React from "react";
import { Link } from "gatsby";
import { AiOutlineHome } from "react-icons/ai";
import { IoIosBulb } from "react-icons/io";
import { GiSparkles, GiCompass } from "react-icons/gi";

export default function Navigation() {
  const navItems = [
    {
      title: "Home",
      renderIcon: () => <AiOutlineHome className="text-lg sm:text-2xl" />,
      url: "/",
    },
    {
      title: "My Story",
      renderIcon: () => <GiCompass className="text-lg sm:text-2xl" />,
      url: "/my-story",
    },
    {
      title: "Journal",
      renderIcon: () => <IoIosBulb className="text-lg sm:text-2xl" />,
      url: "/journal",
    },
    {
      title: "Portfolio",
      renderIcon: () => <GiSparkles className="text-lg sm:text-2xl" />,
      url: "/portfolio",
    },
  ];

  const renderItems = () => {
    return navItems.map((item) => (
      <Link
        key={item.title}
        to={item.url}
        className="p-4 w-1/4 sm:space-x-0 sm:space-y-1 flex flex-col justify-center items-center bg-blue-5 hover:text-green-1"
      >
        <div>{item.renderIcon()}</div>
        <div className="text-xs sm:text-sm text-center">{item.title}</div>
      </Link>
    ));
  };

  return (
    <div className="max-w-full fixed z-10 sm:static bottom-0 left-0 w-full flex flex-row divide-x divide-white divide-solid divide-opacity-10 sm:divide-x sm:divide-y-0">
      {renderItems()}
    </div>
  );
}

// Backup with burger menu
// export default function Navigation() {
//   const [open, setOpen] = useState(false);

//   const navItems = [
//     {
//       title: "Home",
//       renderIcon: () => <AiOutlineHome className="text-2xl" />,
//       url: "/",
//     },
//     {
//       title: "My Story",
//       renderIcon: () => <GiCompass className="text-2xl" />,
//       url: "/my-story",
//     },
//     {
//       title: "Journal",
//       renderIcon: () => <IoIosBulb className="text-2xl" />,
//       url: "journal",
//     },
//     {
//       title: "Portfolio",
//       renderIcon: () => <GiSparkles className="text-2xl" />,
//       url: "portfolio",
//     },
//   ];

//   const renderItems = () => {
//     return navItems.map((item) => (
//       <Link
//       key={item}
//         to={item.url}
//         className="py-4 px-8 flex-1 space-x-2 sm:space-x-0 sm:space-y-1 flex flex-row sm:flex-col justify-center items-center bg-blue-5"
//       >
//         <div>{item.renderIcon()}</div>
//         <div className="text-sm text-center">{item.title}</div>
//       </Link>
//     ));
//   };

//   return (
//     <nav>
//       <button
//         className="sm:hidden flex w-full items-center justify-center p-2 text-gray-400 hover:text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
//         aria-expanded={open}
//         onClick={() => setOpen(!open)}
//       >
//         <span className="sr-only">Open menu</span>

//         {open ? (
//           <RiCloseFill className="text-xl" />
//         ) : (
//           <RiMenu5Line className="text-xl" />
//         )}
//       </button>

//       {open && (
//         <div className="sm:hidden divide-y divide-white divide-solid divide-opacity-10 sm:divide-x sm:divide-y-0">
//           {renderItems()}
//         </div>
//       )}

//       <div className="hidden sm:flex flex-row divide-y divide-white divide-solid divide-opacity-10 sm:divide-x sm:divide-y-0">
//         {renderItems()}
//       </div>
//     </nav>
//   );
// }
