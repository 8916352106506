import React from "react";
import { FaTwitter, FaLinkedin } from "react-icons/fa";

import profileImg from "../images/profile.jpg";

export default function Profile() {
  return (
    <div className="bg-blue-4 text-white p-4 rounded-lg -mt-10">
      <div className="flex flex-col sm:flex-row items-center">
        <img
          className="w-32 h-32 rounded-lg -mt-16"
          src={profileImg}
          alt="Tobias Scharikow"
        />
        <div className="px-8 py-4 text-center sm:text-left">
          <div>
            <div className="text-2xl">Tobias Scharikow</div>
            <div className="mb-2">Indie-Hacker</div>
            <div className="flex justify-center sm:justify-start space-x-4">
              <a
                href="https://twitter.com/refuse2choose"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter />
              </a>
              {/* <FaInstagram /> */}
              <a
                href="https://www.linkedin.com/in/tobias-scharikow/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin />
              </a>

              {/* <FaYoutube /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
