import * as React from "react";

import Navigation from "../components/Navigation";
import Profile from "../components/Profile";
import Header from "../components/Header";
import SEO from "./Seo";
import Footer from "./Footer";

const Layout = ({ children, seo = {} }) => {
  return (
    <div className="dark antialiased w-full h-full min-h-screen bg-blue-3 font-poppins pb-8">
      <SEO {...seo} />

      <Header />

      <div className="w-10/12 mx-auto max-w-7xl">
        <Profile />

        <div className="flex w-full items-start text-white rounded-lg mt-8">
          <div className="w-full max-w-full bg-blue-4">
            <Navigation />
            <main>{children}</main>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
