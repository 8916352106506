import React from "react";

import bgImg from "../images/bg.jpg";

export default function Header() {
  return (
    <header
      className="w-full bg-gray-600 h-60 md:h-80"
      style={{
        background: `url(${bgImg}) no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
  );
}
