import React from "react";
import { Link } from "gatsby";

export default function Footer() {
  return (
    <footer className="static mt-8 w-10/12 rounded-md mx-auto max-w-7xl py-2 px-4 bg-blue-4">
      <ul className="flex justify-center space-x-4 text-white text-sm">
        <li>
          <Link className="hover:text-green-1" to="/imprint">
            Imprint
          </Link>
        </li>
        <li>
          <Link className="hover:text-green-1" to="/privacy">
            Data Privacy
          </Link>
        </li>
        <li>
          <Link className="hover:text-green-1" to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </footer>
  );
}
